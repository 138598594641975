<template>
  <div class="toggle-switch-container">
    <span
      v-html="labelLeft"
      @click="clickLeftLabel()"
    />
    <kendoSwitch
      size="small"
      :class="{'full-color': isSwitchToggle }"
      @change="handleChange"
      :on-label="onLabelText"
      :off-label="offLabelText"
      :disabled="isDisabled"
      :checked="isChecked"
    />
    <span
      v-html="label"
      @click="clickLabel()"
    />
  </div>
</template>

<script>
import {Switch} from '@progress/kendo-vue-inputs'

export default {
  name: 'ToggleSwitch',
  components: {
    'kendoSwitch': Switch
  },
  props: {
    isChecked: {
      type: Boolean,
      default() {
        return false
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    labelLeft: {
      type: String,
      default() {
        return ''
      }
    },
    isDisabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    labelOn: {
      type: String,
      default() {
        return null
      }
    },
    labelOff: {
      type: String,
      default() {
        return null
      }
    }
  },
  methods: {
    handleChange() {
      this.$emit('change-value', !this.isChecked)
    },
    clickLabel() {
      if (!this.isSwitchToggle) {
        this.handleChange()
      } else {
        if (!this.isChecked) {
          this.$emit('change-value', true)
        }
      }
    },
    clickLeftLabel() {
      if (this.isChecked) {
        this.$emit('change-value', false)
      }
    }
  },
  computed: {
    onLabelText() {
      if (this.isSwitchToggle) {
        return null
      }
      return this.labelOn ? this.labelOn : this.$tv('toggleSwitchOnContent', 'inputComponents')
    },
    offLabelText() {
      if (this.isSwitchToggle) {
        return null
      }
      return this.labelOff ? this.labelOff : this.$tv('toggleSwitchOffContent', 'inputComponents')
    },
    isSwitchToggle() {
      return this.labelLeft && this.label
    }
  }
}
</script>

<style lang="scss">
.toggle-switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .k-switch {
    margin: 0 .5rem;
    width: 3.5rem;

    .k-switch-track {
      width: 3.5rem;
    }
  }

  .full-color.k-switch.k-switch-off {
    .k-switch-track {
      background-color: $color__primary;
      border-color: $color__primary;
    }
  }

  > span {
    display: block;
    cursor: pointer;
  }
}
</style>
